import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '../components/Link';
import Layout from '../components/layout/Layout'


export default function About() {
    return (
        <Layout>
            <Box my={4}>
                <Typography variant="h4" component="h1" gutterBottom>
                    About page
                </Typography>
                <Link to="/">Go to the main page</Link>
            </Box>
        </Layout>


    );
}
